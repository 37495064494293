<img style="width: 100%;" src="./../assets/imgs/webDesignBanner.jpg" alt="">
<div class="flexWrap" style="position: relative; top: -6px;">

    <div class="div3cajas colorFondo1">
      <div class="circleTop colorCircle1">
        <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
      </div>
      <div class="divInside">
        <p class="titles">Diseño web</p>
        <p>PWA y paginas web con diseño adaptable a todos los dispositivos</p>
      </div>
    </div>
  
    <div class="div3cajas colorFondo2"> 
      <div class="circleTop colorCircle2">
        <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
      </div>
      <div class="divInside">
      <p class="titles">Marketing en redes sociales</p>
      <p>Potenciamos tu negocio a travez de las redes sociales</p>
    </div>
    </div>
  
    <div class="div3cajas colorFondo1">
      <div class="circleTop colorCircle1">
        <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
      </div>
      <div class="divInside">
      <p class="titles">Servicios para internet</p>
      <p>Hospedaje de tu pagina web y manejo de dominios</p>
    </div>
    </div>
  </div>
  
  <div class="content" role="main">
  

  <app-diseno-web style="position: relative; top: -6px"></app-diseno-web>
  

  
  </div>
