<p>pdfmake works!</p>
<div style="margin-top: 100px;">
    <button (click)="genPDF()">
        Generate PDF
      </button>
      <ul>
        <li>Configuring fonts in <b>app.module.ts</b></li>
        <li>Generating a PDF document in <b>app.component.ts</b></li>
      </ul>
    
</div>
