<div class="divRelative">  
  <div [@animacionTexto]="estadoTexto" class="TextoBanner">
      <div>
          <h2 class="BannerH2" >DISEÑO</h2>
          <h2 class="BannerH2" >DE PAGINAS WEB</h2>
          <!-- <hr style="border-color: #263238;"> -->
          <h3>EXPERIENCIA E INOVACION EN INTERNET</h3>
            <p>
                Expertos en crear tu sitio web: accesible y con el mejor costo-beneficio
            </p>
      </div>        
  </div>    
  <div [@animacionImagenBanner]="estadoImagen" class="BannerImagen">
      <img style="width: 100%;" src="./../../../assets/imgs/loamarillo.png" alt="foto camion cabex">
  </div>

  <img [@animacionLogo]="estadoLogo" class= "logo hideMovile" mat-card-image src="./../../../../assets/imgs/loamarillo.png" alt="Logo Cabex">
  
</div>

<section>
  <div>
    <h1>LISTOS PARA MÓVILES</h1>
    <p>Aprovecha la oportunidad de estar a la mano de tus clientes con un diseño optimizado visual y funcionalmente para dispositivos móviles.</p>
    <p>Creamos páginas y cualquier desarrollo web en plena compatibilidad con cualquier dispositivo móvil, esto es conocido también como diseño responsivo.</p>

    <h2>Desde una web tradicional hasta una PWA</h2>

    <p>Si tu necesidad es una pagina web o bien tener presencia en dispositivos 
      moviles en aplicaciones tenemos la solucion perfecta utilizando las modernas
      tecnicas de desarollo para PWA's (Aplicaciones Web Progresivas) que con una
      sola inversión de codigo te permitiran estar presente en todas las plataformas
    </p>
  
    <p>Entendemos que una página web no solo es subir información. Nuestro compromiso
      es dar a las pequeñas y medianas empresas un diseño acorde a sus necesidades y 
      presupuesto, ofreciendo una pagina web profesional, de facil navegacion y contenido de calidad.
    </p>

  </div>
  
  <div class="cardContainer1">
    <div class="card1">
      <div></div>
      <div>
        <h2>¿Qué es un dominio?</h2>
        <h3>Es: tu NOMBRE en internet</h3>
        <p>¿Es un dominio lo mismo que el hosting? El dominio es tu dirección en internet,
          asi los usuarios podran localizar tu página web y el hosting es el lugar en donde
          estan depositados todos tus archivos que forman la pagina web para que este visible </p>    
        <p>Tener un dominio es fácil y económico y brinda enormes beneficios para ti, el principal  es poseer tu nombre en internet antes que otros lo hagan, de este modo tendrás la seguridad de que tu dominio no podrá ser usados por nadie más en la red.</p>
      </div>    
    </div>
   
    <div class="card1">
      <div></div>
      <div>
        <h2>Recomendaciones en la busqueda o compra de dominios</h2>  
        <p>Es importante tomar en cuenta que sea corto, fácil de recordar y de ser posible que contenga las palabras que identifiquen tu servicio o producto.</p>
        <p>Si necesitas ayuda para elegir un buen nombre en la web con gusto y sin compromiso te asesoramos.</p>
      </div>    
    </div>
  </div>
  
</section>





<!--   
  
  <div class="parallax-container">
    <div class="wrapper">
      <img class="background__image" src="./../../../assets/imgs/taras-shypka-iFSvn82XfGo-unsplash.jpg">
        <img class="middle__image" src="./../../../assets/imgs/loamarillo.png" />

      <img class="foreground__image" src="./../../../assets/imgs/loamarillo.png" />
      <div class="foreground">
        <div class="foreground__content">
            <h1>DISEÑO</h1>
            <H2>DE PAGINAS WEB</H2>
            
        </div>


      </div>
    </div>
  </div> -->
