import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GifsService } from '../services/gifs.service';

@Component({
  selector: 'app-gifsmain',
  templateUrl: './gifsmain.component.html',
  styleUrls: ['./gifsmain.component.scss']
})
export class GifsmainComponent implements OnInit {

@ViewChild('txtBuscar') txtBuscar!: ElementRef<HTMLInputElement>;


  // historial: string[]=[];

  get historial(){
    return this.gifsService.historial;
  }

  constructor(private gifsService: GifsService) { }

  ngOnInit(): void {
    
  }

  buscar(){    
    const valor= this.txtBuscar.nativeElement.value;
    this.gifsService.buscarGifs(valor);
    this.txtBuscar.nativeElement.value='';
    //this.historial= this.gifsService.historial;
  }

  buscar2(item: string){    
    // const valor= this.txtBuscar.nativeElement.value;
    console.log(item);
    this.gifsService.buscarGifs(item);
    // this.txtBuscar.nativeElement.value='';
    //this.historial= this.gifsService.historial;
  }

}
