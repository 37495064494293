import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DisenoWebComponent } from './components/diseno-web/diseno-web.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { JspdfComponent } from './components/jspdf/jspdf.component';
import { MarketingComponent } from './components/marketing/marketing/marketing.component';
import { PdfmakeComponent } from './components/pdfmake/pdfmake.component';

const routes: Routes = [
  {
    path: '',
    component: InicioComponent,
  },
  {
    path: 'inicio',
    component: InicioComponent,
  },
  {
    path: 'diseno',
    component: DisenoWebComponent,
  },
  {
    path: 'jspdf',
    component: JspdfComponent,
  },
  {
    path: 'pdfmake',
    component: PdfmakeComponent,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
