import { Component, OnInit } from '@angular/core';
import { Img, PdfMakeWrapper, Txt } from 'pdfmake-wrapper';
//import pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake

// Set the fonts to use
//PdfMakeWrapper.setFonts(pdfFonts);

@Component({
  selector: 'app-pdfmake',
  templateUrl: './pdfmake.component.html',
  styleUrls: ['./pdfmake.component.scss']
})
export class PdfmakeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  genPDF() {
    const pdf = new PdfMakeWrapper();

    new Img('./../../../assets/imgs/icono_correo.png').width(70).build().then( img => {
    pdf.add( img );
    pdf.add(
      new Txt('Formulario de inscripcion').alignment('center').bold().color('blue').end      
    );

    pdf.add(
      new Txt('variable').end      
    );

    pdf.add(
      new Txt('variable').end      
    );
    pdf.add(
      new Txt('variable').end      
    );
    pdf.add(
      new Txt('variable').end      
    );
    pdf.add(
      new Txt('variable').end      
    );
    pdf.add(
      new Txt('variable').end      
    );

    pdf.create().open();
    //pdf.create().download();

    //pdf.create().download();
    });


  }


  

  // async method
async genPDF2() {
  const pdf = new PdfMakeWrapper();

  // URL
  pdf.add( await new Img('http://domain.com/picture1.jpeg').build() );

  // base64
  pdf.add( await new Img('data:image/jpeg;base64, ...').build() );

  // key: the second param indicates that is a key of a previously saved images using the pdf.images({ ... }) method
  pdf.add( await new Img('myPicture1', true).build() );

  pdf.create().download();
}

}
