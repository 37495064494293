<p>jspdf works!</p>

<div class="container">
    <div class="row">

        <div id="htmlData">
            <img style="width: 200px;" src="./../../../assets/imgs/loamarillo.png" alt="" srcset="">
            <div style="margin-left: 50px;">
                <h1 style="color:blue">SOLICITUD DE INSCRIPCION</h1>
                <p>Nombre del Alumno: Aqui iria el nombre del alumno</p>
                <p>Nombre del Alumno: Aqui iria el nombre del alumno</p>
                <p>Nombre del Alumno: Aqui iria el nombre del alumno</p>
                <p>Nombre del Alumno: Aqui iria el nombre del alumno</p>
                <p>Nombre del Alumno: Aqui iria el nombre del alumno</p>
                <p>Nombre del Alumno: Aqui iria el nombre del alumno</p>

                <img style="width: 200px;" src="./../../../assets/imgs/Celular-RedSocial.png" alt="" srcset="">

                <table>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                    </tr>
                    <tr *ngFor="let user of USERS">
                        <th>{{user.id}}</th>
                        <td>{{user.name}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.phone}}</td>
                    </tr>
                </table>
            </div>



        </div>

        <div class="col-md-4 text-right">
            <button class="btn btn-success btn-block" (click)="openPDF()">Download PDF</button>
        </div>

    </div>
</div>