
<div class="divRelative">  
    <div [@animacionTexto]="estadoTexto" class="TextoBanner">
        <div>
            <h2 class="BannerH2" >MARKETING</h2>
            <h2 class="BannerH2" >DIGITAL</h2>
            <hr style="border-color: #263238;">
            <p>Somos una agencia de marketing digital con servicios integrales de: social media marketing y soluciones a tu medida. Dirige el curso de tu  empresa hacia el marketing en línea con nosotros.</p>
        </div>        
    </div>    
    <div [@animacionImagenBanner]="estadoImagen" class="BannerImagen">
        <img style="width: 100%;" src="./../../../../assets/imgs/Celular-RedSocial.png" alt="foto camion cabex">
    </div>

    <img [@animacionLogo]="estadoLogo" class= "logo" mat-card-image src="./../../../../assets/imgs/loamarillo.png" alt="Logo Cabex">
    
  </div>

  <div style="margin-top: 40px;">
      <div style="text-align: center;">
        <h1 class="h1">SOCIAL MEDIA</h1>
        <h1 class="h1">MARKETING</h1>
        <h3>Estrategias de marketing digital en las redes sociales, obtén más seguidores y enamóralos de tu marca.</h3>
      </div>
      
      <div style="display:flex; flex-wrap: wrap; justify-content: space-around; margin-top: 30px;">
          <div class="card1">
              <div class="div1">
                  <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

              <div class="div2">
                <h2>COMMUNITY MANAGMENT</h2>
                <p>Te ayudamos con tu estrategia de social media marketing</p>
                <p>Te ayudamos en todas tus conversaciones con tus clientes</p>
            </div>
              
          </div>

          <div class="card1">

            <div class="div1">
                <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
              <mat-icon>delete</mat-icon>
            </button>
          </div>

              <div class="div2">
                <h2>ALTA PRESENCIA A BAJO COSTO</h2>
                <p>Podemos utilizar el poder de las redes sociales pada dar a conocer tu marca, tus
                    ideas, productos y servicios con inversiones sumamente accesibleas para cualquier empresa
                </p>
              </div>
            
          </div>


      </div>


      </div>

  <div>

    <app-gifsmain></app-gifsmain>
  

