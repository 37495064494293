import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'app-diseno-web',
  templateUrl: './diseno-web.component.html',
  styleUrls: ['./diseno-web.component.scss'],
  animations: [
    trigger('animacionLogo', [
      state('estado1', style({
        left: '0',
        right: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '1px'
      })),
      state('estado2', style({
        left: '0',
        right: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'absolute',
        width: '20%'
      })),
      transition('estado1 => estado2',
        animate('0.1s ease-in')
      )
    ]),
    trigger('animacionTexto', [
      state('texto1', style({
        transform: 'translateX(-100%)'
      })),
      state('texto2', style({
        // left: '15px',
        // top: '15px'
        // right: '0',
        // marginLeft: '10px',
        // marginRight: 'auto',
      })),
      transition('estado1 => estado2',
        animate('0.1s ease-in')
      ),
      transition('texto1 => texto2',
      animate('0.8s 0s ease-in'))
    ]),

    trigger('animacionImagenBanner', [
      state('EdoImg1', style({
        top:'-500px',
        opacity:'0',
        // left: '0',
        // right: '0',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        width: '1px'
      })),
      state('EdoImg2', style({
        // left: '0',
        // right: '0',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // position: 'absolute',
        transform: 'rotate(-360deg)',
        width: '20%'
      })),
      transition('EdoImg1 => EdoImg2',
        animate('1.4s ease-in')
      )
    ]),

    // trigger('myInsertRemoveTrigger', [
    //   transition(':enter', [
    //     style({ opacity: 0 }),
    //     animate('3000ms', style({ opacity: 1 })),
    //   ]),
    //   transition(':leave', [
    //     animate('3000ms', style({ opacity: 0 }))
    //   ])
    // ]),
  ]
})
export class DisenoWebComponent implements OnInit {
  estadoLogo = 'estado1';
  estadoTexto = 'texto1';
  estadoImagen = 'EdoImg1'
  isShown = false;
  constructor() { }

  ngOnInit(): void {
    if (this.estadoLogo === 'estado1'){
      setTimeout( () => {
        this.estadoLogo = 'estado2';
        this.estadoTexto = 'texto2';
        this.estadoImagen = 'EdoImg2';
        this.isShown = true
      }, 0);
    }

    // if (this.estadoTexto === 'texto1'){
    //   setTimeout( () => this.estadoTexto = 'texto2');
    // }

    // if (this.estadoImagen === 'EdoImg1'){
    //   setTimeout( () => this.estadoImagen = 'EdoImg2');
    // }

    // if (this.isShown === false){
    //   setTimeout( () => this.isShown = true);
    // }
  }

}
