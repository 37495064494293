import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { gifs, gifsInterface } from '../interfaces/gifsInterface';

@Injectable({
  providedIn: 'root'
})
export class GifsService {
  private servicioUrl ='https://api.giphy.com/v1/gifs';
  private apiKey = 'hMsZ2g5wtwZgtKepudt78vOf0d3pFbYy';
  private _historial: string[]=[];

  public resultados: gifs[]=[];

  get historial(){    
    //console.log('Historial', this._historial);
    return [...this._historial];
  }

  constructor(private http: HttpClient) {

    this._historial = JSON.parse(localStorage.getItem('historial')!) || [];
    this.resultados = JSON.parse(localStorage.getItem('resultados')!) || [];

    // if (localStorage.getItem('historial')) {
    //   this._historial = JSON.parse(localStorage.getItem('historial')!);
    // }    
  }

  buscarGifs(query: string){
    query = query.trim().toUpperCase();

    if (!this._historial.includes(query)){
      this._historial.unshift(query);
      this._historial= this._historial.splice(0,9);
      localStorage.setItem('historial', JSON.stringify(this._historial));      
    }
    
    const params = new HttpParams()
    .set('api_key', this.apiKey)
    .set('limit', '20')
    .set('q', query);



    this.http.get<gifsInterface>(`${this.servicioUrl}/search`, {params: params})
    .subscribe( (resp) =>{
      //console.log(resp.data);  
      this.resultados= resp.data;
      localStorage.setItem('resultados', JSON.stringify(this.resultados));
    })
    
  }

}
