<h2>Resultados</h2>

<ul>
    <li *ngFor="let item of resultados">
        
        {{item.title}}
        {{item.images.downsized_medium.url}}
        <img [src]="item.images.downsized_medium.url" alt="" srcset="">
    </li>
</ul>
