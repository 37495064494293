<p>gifsmain works!</p>
<input type="text" 
placeholder="Inserte el nombre"
#txtBuscar
(keyup.enter)="buscar()">


<div>
    <a *ngFor="let item of historial"
    (click)="buscar2(item)">{{item |titlecase}}</a>
    <br>
</div>


<!-- <p *ngFor="let item of historial">
    Hola {{item |titlecase}}
</p> -->

<app-resultados></app-resultados>
