

<mat-sidenav-container>
  <mat-sidenav #snav class="bajo-toolbar">
    <mat-nav-list>
      <a mat-list-item routerLink="inicio">Inicio</a>            
      <a mat-list-item routerLink="contacto">Contacto</a>       
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content> 
    
    <!-- <div style="z-index: 1000; position: fixed; top:0; width: 100%; height: 50px; display: flex; align-items: center; background-color: black; opacity: .5;">
      <button (click)="snav.toggle()" mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
        <mat-icon>menu</mat-icon>
      </button>      
      <button class="hideMovile" mat-button [routerLink]="['inicio']">Inicio</button>
      <button class="hideMovile" mat-button [routerLink]="['diseno']">Diseño web</button>        
      <button class="hideMovile" mat-button [routerLink]="['marketing']">Marketing</button>
    </div> -->

      <mat-toolbar class="toolbar" color="primary">
        <button (click)="snav.toggle()" mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
        <mat-icon>menu</mat-icon>
      </button>      
      <button class="hideMovile" mat-button [routerLink]="['inicio']">Inicio</button>
      <button class="hideMovile" mat-button [routerLink]="['diseno']">Diseño web</button>        
      <button class="hideMovile" mat-button [routerLink]="['marketing']">Marketing</button>
      </mat-toolbar>
            <!-- <div class="bajo-toolbar grid-container">                          -->
              <!-- <main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
                  <router-outlet #o="outlet"></router-outlet>
              </main> -->

              <main style="margin-top: -64px;">
                <router-outlet></router-outlet>
            </main>

            <!-- </div> -->
          </mat-sidenav-content>
        </mat-sidenav-container>

        
    <!-- Footer -->
    <footer style="min-height: 80px; background-color:#2D3C43; color:#c4c4c4; display: flex; align-items: center; justify-content: center;">
      <div>
        <p>Contactenos a: ventas&#64;systam.mx</p>
      </div>
  </footer>
