import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { InicioComponent } from './components/inicio/inicio.component';
import { DisenoWebComponent } from './components/diseno-web/diseno-web.component';
import { MarketingComponent } from './components/marketing/marketing/marketing.component';
import { GifsModule } from './gifs/gifs.module';
import { HttpClientModule } from '@angular/common/http';
import { JspdfComponent } from './components/jspdf/jspdf.component';
import { PdfmakeComponent } from './components/pdfmake/pdfmake.component';

import { PdfMakeWrapper } from 'pdfmake-wrapper';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    DisenoWebComponent,
    MarketingComponent,
    JspdfComponent,
    PdfmakeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    GifsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
